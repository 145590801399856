<template>
    <div class="menu-button" 
        :class="{'is-active': isMenuButtonActive}" 
        @click.prevent="toggle">
        <button class="m-button" title="Menu">
            <span class="button-bar"></span>
            <span class="button-bar"></span>
            <span class="button-bar"></span>
        </button>
    </div>
</template>
<script>
import { store, mutations } from '@/store.js'

export default {
    name: 'MenuButton',
    computed: {
        isMenuButtonActive() {
            return store.isNavOpen;
        }
    },
    methods: {
        toggle() {
            mutations.toggleNav();
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/components/_MenuButton.scss"
</style>