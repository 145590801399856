<template>
  <div id="app" class="site">
    <SiteHeader />
    <main>
      <mq-layout mq="lap+" class="site-nav-container">
        <SiteNav :is-top-nav="true" />
      </mq-layout>
      
      <router-view class="main-content"/>
      
      <SiteFooter />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue';
import SiteNav from '@/components/SiteNav.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteNav,
    SiteFooter
  }
}
</script>


<style lang="scss">
  // The framework-part of the app, never use this 'scoped'
  @import '@/assets/scss/main.scss';
</style>
