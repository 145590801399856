<template>
    <footer class="footer">
        <SiteNav :is-top-nav="false" class="footer__nav" />
        <div class="footer__copyright">&copy; Denis Frietman {{new Date().getFullYear()}}</div>
    </footer>
</template>

<script>
import SiteNav from '@/components/SiteNav.vue'

export default {
    name: 'SiteFooter',
    components: {
        SiteNav
    }
}
</script>
<style lang="scss" scoped>
    footer {
        @include margin(null -#{$spacing*10});
        @include padding($spacing*3 $spacing*10 null);
        align-content: center;
        border-top: 1px solid var(--color-base-moderate);

        @include media($range-palm, $range-hand) {
            .site-nav {
                flex-direction: row;
                font-size: var(--font-size-90);
            }
        }
        @include media($range-lap, false) {
            display: flex;
            justify-content: space-between;
        }
    }
    .footer__copyright {
        line-height: 48px;
        vertical-align: middle;
    }
</style>