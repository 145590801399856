<template>
    <nav class="site-nav">
        <router-link @click.native="closeSidebarPanel" to="/" class="site-nav__home" v-if="isTopNav"><span :data-hover="$t('nav.name')">{{ $t('nav.name') }}</span></router-link>
        <router-link @click.native="closeSidebarPanel" to="/" v-else><span :data-hover="$t('nav.home')">{{ $t('nav.home') }}</span></router-link>
        <router-link @click.native="closeSidebarPanel" to="/about"><span :data-hover="$t('nav.about')">{{ $t('nav.about') }}</span></router-link>
        <router-link @click.native="closeSidebarPanel" to="/projects"><span :data-hover="$t('nav.projects')">{{ $t('nav.projects') }}</span></router-link>
        <router-link @click.native="closeSidebarPanel" to="/contact"><span :data-hover="$t('nav.contact')">{{ $t('nav.contact') }}</span></router-link>
    </nav>
</template>

<script>
import { mutations } from '@/store.js'

export default {
    name: 'SiteNav',
    props: {
        isTopNav: Boolean
    },
    methods: {
        closeSidebarPanel: mutations.closeNav
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/components/_SiteNav.scss';
    @import '@/assets/scss/components/_Link.scss';
    
    .router-link-exact-active:not(.site-nav__home)::after {
        transform: scaleX(1);
    }
</style>