<template>
  <div class="home">
    <div class="intro">
      <p class="chapeau">{{ $t('home.intro.chapeau') }}</p>
      <h1 v-html="$t('home.intro.title')" />
      <!-- <h2 v-html="$t('home.intro.temporary')" /> -->
    </div>
    <div class="projects">
      <p class="chapeau">{{ $t('home.projects.chapeau')}}</p>
      <CaseIntro case-image="hbn-titlecard" title="HBN Law & Tax" chapeau="UI/UX design & Front End Development" route="projects/hbn" />
      <CaseIntro case-image="fast-fluid-titlecard" title="Fast & Fluid Design System" chapeau="UI/UX design" route="projects/fast-fluid" is-flipped />
      <CaseIntro case-image="all-united-titlecard" title="All United Club Software" chapeau="UI/UX design" route="projects/all-united" />
    </div>
    <div class="about">
      <p class="chapeau">{{ $t('home.about.chapeau')}}</p>
      <h2>{{ $t('home.about.title') }}</h2>
      <router-link to="/about" class="button">{{ $t('home.about.link') }}</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CaseIntro from '@/components/CaseIntro.vue'

export default {
  name: 'Home',
  components: {
    CaseIntro
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Home.scss';
</style>