let html = document.querySelector('html');


import Vue from "vue";

// save our state (isPanel open or not) 
export const store = Vue.observable({
    isNavOpen: false,
    isTheme: 'System'
});

// We call toggleNav anywhere we need it in our app
export const mutations = {

    toggleNav() {
        store.isNavOpen = !store.isNavOpen;

        html.classList.toggle('overflow-hidden');
    },
    openNav() {
        store.isNavOpen = true;

        html.classList.add('overflow-hidden');
    },
    closeNav() {
        store.isNavOpen = false;

        html.classList.remove('overflow-hidden');
    },
    setTheme(theme) {
        store.isTheme = theme;
        console.log('setTheme has run');

        if (theme == 'Dark') {
            document.body.classList.remove('theme--system');
            document.body.classList.add('theme--dark');
        } else if (theme == 'System') {
            document.body.classList.remove('theme--dark');
            document.body.classList.add('theme--system');                
        } else {
            document.body.classList.remove('theme--system','theme--dark');
        }
    }
 };