<template>
  <div class="theme-changer">
    <span class="theme-changer__theme" 
          v-for="(theme, i) in themes"
          :key="`theme${i}`" 
          :class="{'is-selected': theme == storedTheme }" 
          >
        <input type="radio" :id="theme" :value="theme" v-model="storedTheme" @change="setTheme">
        <label :for="theme" :title="theme"><i class="fas fa-fw" :class="{'fa-sun': theme == 'Light','fa-code': theme == 'System','fa-moon': theme == 'Dark'}"></i></label>
    </span>
  </div>
</template>

<script>
import { store, mutations } from '@/store.js'

export default {
  name: 'ChangeTheme',
  data () {
    return { 
        storedTheme: store.isTheme,
        themes: ['Light', 'System', 'Dark']
    }
  },
  mounted() {
    if (localStorage.theme) {
      this.storedTheme = localStorage.theme;
    }
    mutations.setTheme(this.storedTheme);
  },
  // beforeMount() {
  //   if (!localStorage.theme) {
  //     mutations.setTheme(this.storedTheme);
  //   }
  // },
  computed: {
        isStoredTheme() {
            return store.isTheme
        }
    },
  methods: {
    setTheme() { 
      localStorage.theme = this.storedTheme;
      mutations.setTheme(this.storedTheme);
      mutations.closeNav();
    }
  }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/components/_ChangeTheme.scss';
</style>