<template>
    <header class="site-header">
      <router-link to="/" class="site-logo">
        <inline-svg :src="require('@/assets/images/denisfrietman-logo.svg')" title="home" width="48" height="48" />
      </router-link>
      <mq-layout mq="lap+" class="u-position-sticky">
        <ChangeTheme />
        <ChangeLocales />
      </mq-layout>
      <mq-layout :mq="['palm', 'hand']">
        <MenuButton />
        <SidePanel>
          <SiteNav :top-nav="false" class="sidepanel__nav" />
          <ChangeTheme />
          <ChangeLocales />
        </SidePanel>
      </mq-layout>
    </header>
</template>
<script>
import ChangeTheme from '@/components/ChangeTheme.vue'
import ChangeLocales from '@/components/ChangeLocales.vue'
import SiteNav from '@/components/SiteNav.vue'
import MenuButton from '@/components/MenuButton.vue'
import SidePanel from '@/components/SidePanel.vue'

export default {
  name: 'SiteHeader',
  data() {
    return {
      menuOpen: false
    }
  },
  components: {
    ChangeTheme,
    ChangeLocales,
    SiteNav,
    MenuButton,
    SidePanel
  }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/components/_SiteHeader.scss';
</style>