<template>
    <div class="case-intro" :class="{'is-flipped': isFlipped}">
        <router-link :to="route" class="case-intro__image">
            <img :src="require(`@/assets/images/cases/${caseImage}.jpg`)" :srcset="require(`@/assets/images/cases/${caseImage}@2x.jpg`) + ' 2x'" alt="">
        </router-link>
        <router-link :to="route" class="case-intro__content">
                <p class="chapeau">{{chapeau}}</p>
                <h2>{{title}}</h2>
                <span class="button"></span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'CaseIntro',
    props: {
        title: String,
        route: String,
        caseImage: String,
        chapeau: String,
        isFlipped: Boolean
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/components/_CaseIntro.scss';
</style>