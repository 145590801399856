import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from './i18n'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueMq from 'vue-mq';

Vue.use(InlineSvgPlugin);

Vue.use(VueMq, {
  breakpoints: {
    palm: 441,
    hand: 681,
    lap: 1041,
    desk: Infinity,
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
