<template>
  <div class="locale-changer">
    <span class="locale-changer__lang" 
          v-for="(lang, i) in langs" 
          :key="`lang${i}`" 
          :class="{'is-selected': lang == $root.$i18n.locale}" 
          >
        <input type="radio" :id="lang" :value="lang" v-model="$root.$i18n.locale">
        <label :for="lang" @click="closeSidebarPanel">{{ lang }}</label>
    </span>
  </div>
</template>

<script>
import { mutations } from '@/store.js'

export default {
  name: 'ChangeLocales',
  data () {
    return { 
        langs: ['nl', 'en']
    }
  },
  methods: {
    closeSidebarPanel: mutations.closeNav,
  }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/components/_ChangeLocales.scss';
</style>